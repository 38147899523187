import packageJson from '../package.json';
import toastError from './errors/toastError';
import api from './services/api';

const { version } = packageJson;
function getConfig(name, defaultValue=null) {
    // If inside a docker container, use window.ENV
    if( window.ENV !== undefined ) {
        return window.ENV[name] || defaultValue;
    }

    return process.env[name] || defaultValue;
}

export function getBackendUrl() {
    return getConfig('REACT_APP_BACKEND_URL');
}
export function getAppname(){
    return getConfig('REACT_APP_NAME')||"Multiatendimento";
}
export const getVersion=async()=> {
    let apiVersion = '';
    let api2Version = '';
    try {
        const { data } = await api.get("/changelog");
        apiVersion = data?.api_version.replace('^','');
        api2Version = data?.api2_version.replace('^', '');
    } catch (err) {
        toastError(err);
    }
    let versionAll = {api2Version,apiVersion,version}
    return versionAll;
}
