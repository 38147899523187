import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CircularProgress,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Tooltip,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { CheckCircle, CropFree, ExpandMore, SignalCellular4Bar, SignalCellularConnectedNoInternet0Bar, SignalCellularConnectedNoInternet2Bar } from "@mui/icons-material";
import { i18n } from "../../translate/i18n";
import { green } from "@mui/material/colors";
import { getBackendUrl } from "../../config";
import useWhatsApps from "../../hooks/useWhatsApps";
import useSettings from "../../hooks/useSettings";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        alignItems: "center",
        marginBottom: 12,
    },
    heading: {
        fontSize: theme.typography.pxToRem(17),
        fontWeight: theme.typography.fontWeightMedium,
    },
    mainpaper: {
        padding: theme.spacing(2),
        alignItems: "center",
        marginBottom: 12,
        backgroundColor: 'transparent'
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        backgroundColor: 'transparent'
    },
    childpaper: {
        backgroundColor: theme.palette.background.default + '5b'
    },
    iconButton: {
        color: theme.palette.text.secondary
    },
    rawExampleSectionCode: {
        backgroundColor: "#212121",
        padding: '5px',
        borderRadius: '5px',
        color: "#ffffff"
    },

}));

const IntegrationSettings = ({ apiToken }) => {
    const classes = useStyles();
    const { whatsApps } = useWhatsApps();
    const { settings } = useSettings();

    const getSettingValue = key => {
        const { value } = settings.find(s => s.key === key);
        return value;
    };

    const renderStatusToolTips = whatsApp => {
        return (
            <div className={classes.customTableCell}>
                {whatsApp.status === "DISCONNECTED" && (
                    <CustomToolTip
                        title={i18n.t("connections.toolTips.disconnected.title")}
                        content={i18n.t("connections.toolTips.disconnected.content")}
                    >
                        <SignalCellularConnectedNoInternet0Bar color="secondary" />
                    </CustomToolTip>
                )}
                {whatsApp.status === "OPENING" && (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                )}
                {whatsApp.status === "qrcode" && (
                    <CustomToolTip
                        title={i18n.t("connections.toolTips.qrcode.title")}
                        content={i18n.t("connections.toolTips.qrcode.content")}
                    >
                        <CropFree />
                    </CustomToolTip>
                )}
                {whatsApp.status === "CONNECTED" && (
                    <CustomToolTip title={i18n.t("connections.toolTips.connected.title")}>
                        <SignalCellular4Bar style={{ color: green[500] }} />
                    </CustomToolTip>
                )}
                {(whatsApp.status === "TIMEOUT" || whatsApp.status === "PAIRING") && (
                    <CustomToolTip
                        title={i18n.t("connections.toolTips.timeout.title")}
                        content={i18n.t("connections.toolTips.timeout.content")}
                    >
                        <SignalCellularConnectedNoInternet2Bar color="secondary" />
                    </CustomToolTip>
                )}
            </div>
        );
    }
    const CustomToolTip = ({ title, content, children }) => {
        const classes = useStyles();

        return (
            <Tooltip
                arrow
                classes={{
                    tooltip: classes.tooltip,
                    popper: classes.tooltipPopper,
                }}
                title={
                    <React.Fragment>
                        <Typography gutterBottom color="inherit">
                            {title}
                        </Typography>
                        {content && <Typography>{content}</Typography>}
                    </React.Fragment>
                }
            >
                {children}
            </Tooltip>
        );
    };
    return (
        <Paper elevation={0} variant="outlined" className={classes.childpaper}>
            <AccordionDetails>
                {i18n.t("settings.integration.description")}
            </AccordionDetails>
            <Paper elevation={0} className={classes.mainpaper}>
                <Typography variant="body1">
                    {i18n.t("settings.integration.apiUrl")}{':'}
                </Typography>
                <pre className={classes.rawExampleSectionCode}>
                    <code>
                        {`POST\n${getBackendUrl()}/api/messages/send`}
                    </code>
                </pre>
                <Typography variant="body1">
                    {i18n.t("settings.integration.headers")}{':'}
                </Typography>
                <pre className={classes.rawExampleSectionCode}>
                    <code>
                        {`{\n	"Authorization":"Bearer ${settings && settings.length > 0 && getSettingValue("userApiToken")}",\n	"Content-Type": "application/json"\n}`}
                    </code>
                </pre>
                <Typography variant="body1">
                    {i18n.t("settings.integration.jsonFormat")}{':'}
                </Typography>
                <pre className={classes.rawExampleSectionCode}>
                    <code>
                        {`{\n	"number": "${i18n.t("settings.integration.examples.number")}",\n	"body": "${i18n.t("settings.integration.examples.body")}",\n	"whatsappId":"${i18n.t("settings.integration.examples.whatsappId")}"\n}`}
                    </code>
                </pre>

                <Typography variant="body1">
                    {i18n.t("settings.integration.connections")}{':'}
                </Typography>
                <List fullWidth>
                    {whatsApps.map((whatsapp) => (
                        <ListItem>
                            <ListItemText primary={whatsapp.id} />
                            <ListItemText primary={whatsapp.name} />
                            <ListItemIcon>
                                {renderStatusToolTips(whatsapp)}
                            </ListItemIcon>
                            <ListItemIcon>
                                {whatsapp.isDefault && (
                                    <div className={classes.customTableCell}>
                                        <CheckCircle style={{ color: green[500] }} />
                                    </div>
                                )}
                            </ListItemIcon>
                        </ListItem>
                    ))}
                </List>
            </Paper>
        </Paper>

    )
}
export default IntegrationSettings;