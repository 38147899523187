import { useContext, useEffect, useReducer, useState } from "react";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import { Avatar, Button, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { Api, CodeOff, DeleteOutline, Edit, GroupAdd, LineAxis, QueryStats, Search as SearchIcon, Webhook } from "@mui/icons-material";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { Can } from "../../components/Can";
import { AuthContext } from "../../context/Auth/AuthContext";
import makeStyles from "@mui/styles/makeStyles";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import openSocket from "../../services/socket-io";
import ChatGPTIcon from "../../assets/icons/ChatGPTIcon";
import IntegrationModal from "../../components/IntegrationModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast } from "react-toastify";
import MovideskIcon from "../../assets/icons/MovideskIcon";
//import IntegrationStatsModal from "../../components/IntegrationStatsModal";


const useStyles = makeStyles(theme => ({
    mainPaper: {
        flex: 1,
        margin: theme.spacing(1),
        marginTop: 0,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    iconButton: {
        color: theme.palette.text.secondary
    },
    trashIcon: {
        color: theme.palette.secondary.main
    },
    tableCustomRow: {
        '& td, & th': {
            borderColor: theme.palette.divider,
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 130,
    },
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_INTEGRATIONS") {
        const integrations = action.payload;
        const newIntegration = [];

        integrations.forEach((integration) => {
            const integrationIndex = state.findIndex((i) => i.id === integration.id);
            if (integrationIndex !== -1) {
                state[integrationIndex] = integration;
            } else {
                newIntegration.push(integration);
            }
        });

        return [...state, ...newIntegration];
    }

    if (action.type === "UPDATE_INTEGRATION") {
        const integration = action.payload;
        const integrationIndex = state.findIndex((i) => i.id === integration.id);

        if (integrationIndex !== -1) {
            state[integrationIndex] = integration;
            return [...state];
        } else {
            return [integration, ...state];
        }
    }

    if (action.type === "DELETE_INTEGRATION") {
        const integrationId = action.payload;

        const integrationIndex = state.findIndex((i) => i.id === integrationId);
        if (integrationIndex !== -1) {
            state.splice(integrationIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
}

const Integrations = () => {
    const classes = useStyles();
    const [count, setCount] = useState(0);
    const { user } = useContext(AuthContext);
    const [integrations, dispatch] = useReducer(reducer, []);
    const [loading, setLoading] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [integrationModalOpen, setIntegrationModalOpen] = useState(false);
    //const [integrationStatsModalOpen, setIntegrationStatsModalOpen] = useState(false);
    const [selectedIntegrationId, setSelectedIntegrationId] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        setLoading(true);
        //const delayDebounceFn = setTimeout(() => {
            const fetchIntegrations = async () => {
                try {
                    const { data } = await api.get("/integrations/", {
                        params: { searchParam, pageNumber },
                    });
                    dispatch({ type: "LOAD_INTEGRATIONS", payload: data.integrations });
                    setHasMore(data.hasMore);
                    setCount(data.count);
                } catch (err) {
                    toastError(err);
                }
                setLoading(false);
            };
            fetchIntegrations();
        // }, 500);
        // return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber]);

    useEffect(() => {
        const socket = openSocket();
        socket.on("integration", (data) => {
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_INTEGRATION", payload: data.integration });
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_INTEGRATION", payload: +data.integrationId });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    const handleSearch = (event) => {
        setSearchParam(event.target.value);
    };
    
    const handleOpenIntegrationModal = () => {
        setIntegrationModalOpen(true);
    };

    const handleCloseIntegrationModal = () => {
        setSelectedIntegrationId(null);
        setIntegrationModalOpen(false);
    };

    const handleCloseConfirmationModal = () => {
        setConfirmModalOpen(false);
        setSelectedIntegrationId(null);
    };

    // const handleOpenIntegrationStatsModal = () => {
    //     setIntegrationStatsModalOpen(true);
    // };
    
    // const handleCloseIntegrationStatsModal = () => {
    //     setSelectedIntegrationId(null);
    //     setIntegrationStatsModalOpen(false);
    // };
    const handleDeleteIntegration = async (integrationId) => {
        try {
            await api.delete(`/integrations/${integrationId}`);
            toast.success(i18n.t("integrations.deleteConfirmationModal.deleteSuccess"));
        } catch (err) {
            toastError(err);
        }
        setSelectedIntegrationId(null);
    };


    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
            setLoading(true);
        }
    };


    const renderIntegrationIcon = (integration) => {
        const iconMapping = {
            "openai": <ChatGPTIcon color='textPrimary' />,
            "movidesk": <MovideskIcon color="textPrimary" />,
            "api": <Api color='textPrimary' />,
            "webhook": <Webhook color='textPrimary' />,
        };
        return iconMapping[integration] || <CodeOff color='textPrimary' />;
    };

    return (
        <Can
            role={user.profile}
            perform="drawer-admin-items:view"
            yes={() => (
                <MainContainer className={classes.mainContainer}>
                    <IntegrationModal
                        open={integrationModalOpen}
                        onClose={handleCloseIntegrationModal}
                        aria-labelledby="form-dialog-title"
                        integrationId={selectedIntegrationId}
                    />
                   {/*  <IntegrationStatsModal 
                        open={integrationStatsModalOpen}
                        onClose={handleCloseIntegrationStatsModal}
                        aria-labelledby="form-dialog-title"
                        integrationId={selectedIntegrationId}
                    /> */}
                    <ConfirmationModal 
                        title={
                            selectedIntegrationId &&
                            `${i18n.t("integrations.deleteConfirmationModal.title")} ${selectedIntegrationId
                            }?`
                        }
                        open={confirmModalOpen}
                        onClose={handleCloseConfirmationModal}
                        onConfirm={() => handleDeleteIntegration(selectedIntegrationId)}
                    >
                        {i18n.t("integrations.deleteConfirmationModal.warning")}
                    </ConfirmationModal>
                    <MainHeader>
                        <Title>{`${i18n.t("integrations.title")} (${count})`}</Title>
                        <MainHeaderButtonsWrapper>
                            <TextField
                                placeholder={i18n.t("integrations.searchPlaceholder")}
                                type="search"
                                value={searchParam}
                                onChange={handleSearch}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon style={{ color: "gray" }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleOpenIntegrationModal}
                            >
                                {i18n.t("integrations.buttons.create")}
                            </Button>
                        </MainHeaderButtonsWrapper>
                    </MainHeader>
                    <Paper
                        className={classes.mainPaper}
                        variant="outlined"
                        onScroll={handleScroll}
                    >
                        <Table size='small'>
                            <TableHead>
                                <TableRow className={classes.tableCustomRow}>
                                    <TableCell padding="checkbox" />
                                    <TableCell align="center">
                                        {i18n.t("integrations.table.name")}
                                    </TableCell>
                                    <TableCell align="center">
                                        {i18n.t("integrations.table.type.title")}
                                    </TableCell>
                                    <TableCell align="center">
                                        {i18n.t("integrations.table.status.title")}
                                    </TableCell>
                                    <TableCell align="center">
                                        {i18n.t("integrations.table.actions")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {integrations.map((integration) => (
                                    <TableRow className={classes.tableCustomRow} key={integration.id} >
                                        <TableCell align="center">
                                            {renderIntegrationIcon(integration.type)}
                                        </TableCell>
                                        <TableCell align="center">
                                            {integration.name}
                                        </TableCell>
                                        <TableCell align="center">
                                            {i18n.t(`integrations.table.type.${integration?.type || "unknown"}`)}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography variant="body2" component='p'>{i18n.t(`integrations.table.status.${integration?.status||"unknown"}`)}</Typography>
                                            {integration.type === "openai" &&
                                                <Tooltip title={<>
                                                    <Typography variant="body2" component='p'>{i18n.t("integrations.table.openai.promptTokens", { count: integration?.promptTokens || 0 })}</Typography>
                                                    <Typography variant="body2" component='p'>{i18n.t("integrations.table.openai.completionTokens", { count: integration?.completionsTokens || 0 })}</Typography>
                                                    <Typography variant="body2" component='p'>{i18n.t("integrations.table.openai.totalTokens", { count: integration?.totalTokens || 0 })}</Typography>
                                                </>}>
                                                    <Typography variant="body2" component='p'>{i18n.t("integrations.table.openai.usedTokens", { count: integration?.totalTokens || 0 })}</Typography>
                                                </Tooltip>
                                            }
                                        </TableCell>
                                        <TableCell align="center">
                                            {/* <IconButton
                                                size="small"
                                                className={classes.iconButton}
                                                onClick={() => {
                                                    setSelectedIntegrationId(integration.id);
                                                    handleOpenIntegrationStatsModal();
                                                }}
                                            >
                                                <QueryStats />
                                            </IconButton> */}
                                            <IconButton
                                                size="small"
                                                className={classes.iconButton}
                                                onClick={() => {
                                                    setSelectedIntegrationId(integration.id);
                                                    handleOpenIntegrationModal();
                                                }}
                                            >
                                                <Edit />
                                            </IconButton>

                                            <IconButton
                                                size="small"
                                                className={classes.trashIcon}
                                                onClick={() => {
                                                    setSelectedIntegrationId(integration.id);
                                                    setConfirmModalOpen(true);
                                                }}
                                            >
                                                <DeleteOutline />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {loading && <TableRowSkeleton columns={4} avatar={1} />}
                            </TableBody>
                        </Table>
                    </Paper>
                </MainContainer>
            )}
        />
    );
}
export default Integrations;