import { Menu, MenuItem } from "@mui/material";
import { useContext, useState } from "react";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import { Can } from "../Can";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import TransferTicketModal from "../TransferTicketModal";
import { i18n } from "../../translate/i18n";
import ContactModal from "../ContactModal";
import ConfirmationModal from "../ConfirmationModal";

const TicketListContextMenu = ({ ticket, anchorEl, menuOpen, handleClose, onSelectTicket, selectedTickets }) => {
    const { settings } = useContext(SettingsContext);
    const { user } = useContext(AuthContext);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
    const [contactModalOpen, setContactModalOpen] = useState(false);
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const getSettingValue = (key) => {
        if (settings && settings.length > 0) {
            const { value } = settings.find(s => s.key === key);
            return value;
        }
    };

    const anonymousTicket = () => {
        const canView = Can({
            role: user.profile,
            perform: "drawer-admin-items:view",
            yes: () => true,
            no: () => false,
        });
        const isAnonymous = getSettingValue('anonymoustickets')
        if (isAnonymous === 'enabled' && !canView) {
            return true;
        } else if (isAnonymous === 'disabled' || canView) {
            return false;
        } else return false;
    };

    const isWhatsapp = (e) => {
        if (ticket.whatsapp?.connectionType === 'whatsapp'
            || ticket.whatsapp?.connectionType === 'bwhatsapp'
			/* || !ticket.contact?.connectionId */) {
            return true;
        }
        return false;
    };

    const isGroupAsTicket = getSettingValue('groupsasticket') === "enabled" ? true : false;


    const handleSelectTicket = () => {
        if (anonymousTicket() && ticket.status === 'pending') return;
        history.push(`/tickets/${ticket.id}`);
        handleClose();
    };

    const handleDeleteTicket = async () => {
        try {
            await api.delete(`/tickets/${ticket.id}`);
        } catch (err) {
            toastError(err);
        }
    };

    const handleSelect = () => {
        onSelectTicket(ticket.id);
        handleClose();
    }

    const handleOpenConfirmationModal = e => {
        setConfirmationOpen(true);
        handleClose();
    };

    const handleOpenTransferModal = (e) => {
        setTransferTicketModalOpen(true);
        handleClose();
    };

    const handleCloseTransferTicketModal = (e) => {
        setTransferTicketModalOpen(false);
    };

    const handleOpenContactModal = (e) => {
        setContactModalOpen(true);
        handleClose();
    };

    const handleCloseContactModal = (e) => {
        setContactModalOpen(false);
    };

    const handleUpdateTicketStatus = async (e, status, userId) => {
        setLoading(true);
        try {
            await api.put(`/tickets/${ticket.id}`, {
                status,
                userId,
            });

            setLoading(false);
            if (status === "open") {
                history.push(`/tickets/${ticket.id}`);
            } else {
                history.push("/tickets");
            }
        } catch (err) {
            setLoading(false);
            toastError(err);
        }
        handleClose();
    };

    const setTicketAsUnread = async (count = 1) => {
        setLoading(true);
        try {
            await api.put(`/tickets/${ticket.id}`, {
                unreadMessages: count
            });
            setLoading(false);
        } catch (err) {
            toastError(err);
            setLoading(false);
        }
        handleClose();

    }

    const handleUpdateTickets = async (ticketData) => {
        if (selectedTickets && selectedTickets.length === 0) return;
        await api.put(`/tickets`, {
            ids: selectedTickets,
            ticketData
        });
        if (ticketData.status) {
            //setSelectedTickets([])
        }
        handleClose();
    }

    return (
        <>
            <Menu
                open={menuOpen}
                anchorReference="anchorPosition"
                anchorPosition={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <MenuItem
                    disabled={loading}
                    onClick={handleSelect}
                >
                    {selectedTickets.some((ticketId) => ticket.id === ticketId) ? "Desmarcar" : "Selecionar"}
                </MenuItem>

                {selectedTickets.length === 0 && !anonymousTicket() && (
                    <MenuItem
                        disabled={loading}
                        onClick={handleSelectTicket}
                    >
                        Abrir
                    </MenuItem>
                )}

                {selectedTickets.length === 0 && ticket.status === "open" && (ticket.userId === user.id || user.profile === 'admin') && (!ticket.isGroup || (ticket.isGroup && isGroupAsTicket)) && (
                    <div>
                        <MenuItem
                            disabled={loading}
                            onClick={(e) => handleUpdateTicketStatus(e, "closed", ticket.useId)}
                        >
                            {i18n.t("messagesList.header.buttons.resolve")}
                        </MenuItem>
                        {!anonymousTicket() && (
                            <MenuItem
                                disabled={loading}
                                onClick={(e) => handleUpdateTicketStatus(e, "pending", null)}
                            >
                                {i18n.t("messagesList.header.buttons.return")}
                            </MenuItem>
                        )}
                    </div>
                )}

                {selectedTickets.length === 0 && ticket.status === "closed" && (ticket.userId === user.id || user.profile === 'admin' || ticket.isGroup) && (
                    <MenuItem
                        disabled={loading}
                        onClick={(e) => handleUpdateTicketStatus(e, "open", user.id)}
                    >
                        {i18n.t("messagesList.header.buttons.reopen")}
                    </MenuItem>
                )}

                {selectedTickets.length === 0 && ticket.status === "pending" && (
                    <MenuItem
                        disabled={loading}
                        onClick={(e) => handleUpdateTicketStatus(e, "open", user.id)}
                    >
                        {i18n.t("messagesList.header.buttons.accept")}
                    </MenuItem>
                )}

                <MenuItem
                    disabled={loading}
                    onClick={() => setTicketAsUnread(ticket.unreadMessages > 0 ? 0 : 1)}
                >
                    Marcar como {ticket.unreadMessages === 0 && "não"} lido
                </MenuItem>

                {selectedTickets.length === 0 && ticket.status === "open" && (ticket.userId === user.id || user.profile === 'admin') && (
                    <MenuItem
                        disabled={loading}
                        onClick={handleOpenTransferModal}
                    >
                        {i18n.t("ticketOptionsMenu.transfer")}
                    </MenuItem>
                )}

                {selectedTickets.length === 0 && ticket.status !== "pending" && anonymousTicket() && (
                    <MenuItem
                        disabled={loading}
                        onClick={handleOpenContactModal}
                    >
                        {i18n.t("ticketDrawer.buttons.edit", { type: ticket.isGroup ? i18n.t("ticketDrawer.group") : i18n.t("ticketDrawer.contact") })}
                    </MenuItem>
                )}

                {selectedTickets.length === 0 && (
                    <Can
                        role={user.profile}
                        perform="ticket-options:deleteTicket"
                        yes={() => (
                            <MenuItem
                                disabled={loading}
                                onClick={handleOpenConfirmationModal}
                            >
                                {i18n.t("ticketOptionsMenu.delete")}
                            </MenuItem>
                        )}
                    />
                )}

                {selectedTickets.length > 0 && (

                    <MenuItem
                        disabled={loading}
                        onClick={() => handleUpdateTickets({ unreadMessages: 1 })}
                    >
                        Marcar como não lidos
                    </MenuItem>
                )}
                {selectedTickets.length > 0 && (

                    <MenuItem
                        disabled={loading}
                        onClick={() => handleUpdateTickets({ unreadMessages: 0 })}
                    >
                        Marcar como lidos
                    </MenuItem>

                )}
            </Menu>

            <ConfirmationModal
                title={`${i18n.t("ticketOptionsMenu.confirmationModal.title", { ticketId: ticket.id, contactName: ticket.contact?.name })}`}
                open={confirmationOpen}
                onClose={setConfirmationOpen}
                onConfirm={handleDeleteTicket}
            >
                {i18n.t("ticketOptionsMenu.confirmationModal.message")}
            </ConfirmationModal>
            <TransferTicketModal
                modalOpen={transferTicketModalOpen}
                onClose={handleCloseTransferTicketModal}
                ticketid={ticket.id}
                isGroup={ticket.isGroup}
                isWhatsapp={isWhatsapp}
                ticketWhatsappId={ticket.whatsappId}
            />
            <ContactModal
                open={contactModalOpen}
                onClose={handleCloseContactModal}
                contactId={ticket.contactId}
            />
        </>


    )
}
export default TicketListContextMenu;