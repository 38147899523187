import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormHelperText, IconButton, Input, InputAdornment, InputLabel, MenuItem, Select, Slider, Switch, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { i18n } from "../../translate/i18n";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import * as Yup from "yup";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { toast } from "react-toastify";
import axios from "axios";
import CreateToolsJson from "./createToolsJson";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
        minWidth: 200
    },

    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    inlineWrapper: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        flexWrap: "wrap",
        gap: 4
    },
    multiLine: {
        display: "flex",
        gap: 4,
        flex: 1,
    },
    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: theme.palette.primary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    trashIcon: {
        color: theme.palette.secondary.main
    },
    toolBox: {
        padding: 7,
        borderRadius: 4,
        border: `1px solid ${theme.palette.divider}`,
        display: "flex",
        flexDirection: "column"
    }
}));

const models = [
    'gpt-4-0125-preview',
    'gpt-4-turbo-preview',
    'gpt-4-1106-preview',
    'gpt-4-vision-preview',
    'gpt-4',
    'gpt-4-0314',
    'gpt-4-0613',
    'gpt-4-32k',
    'gpt-4-32k-0314',
    'gpt-4-32k-0613',
    'gpt-3.5-turbo',
    'gpt-3.5-turbo-16k',
    'gpt-3.5-turbo-0301',
    'gpt-3.5-turbo-0613',
    'gpt-3.5-turbo-1106',
    'gpt-3.5-turbo-0125',
    'gpt-3.5-turbo-16k-0613'
];

const IntegrationSchema = Yup.object().shape({
    type: Yup.string().required("Obrigatório!"),
    name: Yup.string()
        .max(100, "Muito longo!")
        .required("Obrigatório!"),
    apiKey: Yup.string().required("Obrigatório"),
    model: Yup.string().required("Obrigatório").oneOf(models, 'Modelo não corresponde a nenhum modelo válido'),
    instruction: Yup.string().max(500, "Muito longo!"),
    email: Yup.string().email("Email inválido!").max(50, "Muito longo!"),
    name: Yup.string()
        .max(100, "Muito longo!"),
    temperature: Yup.number().required("Obrigatório").test("Check-temperature-value",
        "Valores aceitos somente entre 0 e 2", (value) => value <= 2 && value >= 0),
    maxTokens: Yup.number().integer("Somente números inteiros").required("Obrigatório"),
    historyLimit: Yup.number().integer("Somente números inteiros").required("Obrigatório"),
    onlyTicket: Yup.boolean(),
});



const IntegrationModal = ({ open, onClose, integrationId, initialValues }) => {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const initialState = {
        type: "openai",
        name: "",
        model: "gpt-4-0125-preview",
        apiKey: "",
        instructions: "",
        assName: "",
        temperature: 1,
        maxTokens: 256,
        historyLimit: 30,
        onlyTicket: true,
        tools: [
            {
                type: "function",
                function: {
                    name: "transfer_to_queue_or_attendant",
                    description: "Transferir para uma fila/setor e atendente somente se existir",
                    parameters: {
                        type: "object",
                        properties: {
                            queue: {
                                type: "string",
                                description: "Se não existir o nome da fila/setor dito pelo usuário, comparado aos nomes fornecidos, deixe vazio."
                            },
                            attendant: {
                                type: "string",
                                description: "Se não existir o nome de atendente dito pelo usuário, comparado aos nomes fornecidos, deixe vazio."
                            },
                            message: {
                                type: "string",
                                description: "Se não existir o atendente de mesmo nome, avise que ele não faz parte da equipe. Caso exista, diga que vai transferir a conversa.",
                            },
                        },
                        required: ["message"],
                    },
                }
            },
        ]
    };
    const [integration, setIntegration] = useState(initialState);


    useEffect(() => {
        const fetchIntegration = async () => {
            if (!integrationId) return;
            try {
                const { data } = await api.get(`/integrations/${integrationId}`);
                setIntegration(data.integration);
            } catch (err) {
                setIntegration(initialState);
                toastError(err);
            }
        };

        fetchIntegration();
    }, [integrationId, open, initialValues]);

    const handleClose = () => {
        onClose();
        setIntegration(initialState);
    }

    const handleSaveIntegration = async (values) => {
        try {
            if (integrationId) {
                await api.put(`/integrations/${integrationId}`, values);
            } else {
                await api.post("/integrations", values);
            }
            toast.success(i18n.t("integrationModal.success"));
        } catch (err) {
            toastError(err);
        }
        handleClose();
    }
    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle
                    id="form-dialog-title"
                    color="textPrimary"
                >
                    {integrationId
                        ? `${i18n.t("integrationModal.title.edit")}`
                        : `${i18n.t("integrationModal.title.add")}`
                    }
                </DialogTitle>
                <Formik
                    initialValues={integration}
                    enableReinitialize={true}
                    validationSchema={IntegrationSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveIntegration(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                        <Form>
                            <DialogContent dividers>
                                <div style={{ display: "flex", gap: 4 }}>
                                    <FormControl
                                        className={classes.multiLine}
                                        variant="outlined"
                                        margin="dense"
                                        error={Boolean(errors.type)}

                                    >
                                        <InputLabel>{i18n.t("integrationModal.form.type")}</InputLabel>
                                        <Field
                                            label={i18n.t("integrationModal.form.type")}
                                            as={Select}
                                            color="primary"
                                            name="type"
                                        >
                                            <MenuItem value={'openai'}>{i18n.t("integrationModal.form.options.openai")}</MenuItem>
                                            <MenuItem value={'movidesk'}>{i18n.t("integrationModal.form.options.movidesk")}</MenuItem>
                                            {/* <MenuItem value={'api'}>{i18n.t("integrationModal.form.options.api")}</MenuItem>
                                            <MenuItem value={'webhhok'}>{i18n.t("integrationModal.form.options.webhook")}</MenuItem> */}
                                        </Field>
                                        <FormHelperText>{errors.type}</FormHelperText>
                                    </FormControl>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("integrationModal.form.name")}
                                        name="name"
                                        autoFocus
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.multiLine}
                                    />
                                </div>
                                <div style={{ display: "flex", gap: 4 }}>
                                    {values.type === "openai" &&
                                        <FormControl
                                            className={classes.multiLine}
                                            variant="outlined"
                                            margin="dense"
                                            error={Boolean(errors.model)}
                                        >
                                            <InputLabel>{i18n.t("integrationModal.form.model")}</InputLabel>
                                            <Field
                                                label={i18n.t("integrationModal.form.model")}
                                                as={Select}
                                                color="primary"
                                                name="model"
                                            >
                                                {models.map(model =>
                                                    <MenuItem value={model}>{model}</MenuItem>
                                                )}
                                            </Field>
                                            <FormHelperText>{errors.model}</FormHelperText>
                                        </FormControl>}
                                    <Field
                                        as={TextField}
                                        label={i18n.t("integrationModal.form.apiKey")}
                                        name="apiKey"
                                        type={showPassword ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowPassword((e) => !e)}
                                                        size="large">
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        error={touched.apiKey && Boolean(errors.apiKey)}
                                        helperText={touched.apiKey && errors.apiKey}
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.multiLine}
                                    />
                                </div>
                                {values.type === 'openai' &&
                                    <>
                                        <Field
                                            as={TextField}
                                            label={i18n.t("integrationModal.form.instructions")}
                                            name="instructions"
                                            error={touched.instructions && Boolean(errors.instructions)}
                                            helperText={touched.instructions && errors.instructions}
                                            fullWidth
                                            margin="dense"
                                            variant="outlined"
                                            multiline
                                            placeholder="Por favor, insira o conteúdo completo que deseja que o atendente virtual processe. Este texto deve incluir todas as informações relevantes que você gostaria que o atendente virtual compartilhasse ou respondesse. Certifique-se de fornecer detalhes claros e completos, incluindo endereços, números de contato, descrições de serviços ou qualquer outra informação relevante para a interação com o usuário. Este texto será usado para alimentar a conversa com o atendente virtual da API da OpenAI."
                                            rows={6}
                                        />
                                        <div style={{ display: "flex", gap: 4 }}>
                                            <FormControl
                                                className={classes.textField}
                                                variant="outlined"
                                                margin="dense"
                                                error={Boolean(errors.temperature)}
                                            >
                                                <InputLabel shrink id="input-slider">{i18n.t("integrationModal.form.temperature")}</InputLabel>
                                                <Box display="flex" justifyContent="space-between">
                                                    <Field
                                                        aria-labelledby="input-slider"
                                                        as={Slider}
                                                        name="temperature"
                                                        //shiftStep={10}
                                                        marks
                                                        step={0.1}
                                                        min={0}
                                                        max={2}
                                                        sx={{ flex: 1 }}
                                                    />
                                                    <Input
                                                        sx={{ flex: "0 1 40px", m: "auto", textAlign: "right" }}
                                                        color="primary"
                                                        value={values.temperature}
                                                        onChange={(e) => setFieldValue("temperature", e.target.value)}
                                                        inputProps={{
                                                            step: 0.1,
                                                            min: 0,
                                                            max: 2,
                                                            type: 'number',
                                                            'aria-labelledby': 'input-slider',
                                                            style: { textAlign: "center" }
                                                        }}
                                                    />
                                                </Box>
                                            </FormControl>
                                            <Field
                                                as={TextField}
                                                label={i18n.t("integrationModal.form.maxTokens")}
                                                name="maxTokens"
                                                type="number"
                                                error={touched.maxTokens && Boolean(errors.maxTokens)}
                                                helperText={touched.maxTokens && errors.maxTokens}
                                                variant="outlined"
                                                margin="dense"
                                                className={classes.multiLine}
                                            />
                                            <Field
                                                as={TextField}
                                                label={i18n.t("integrationModal.form.historyLimit")}
                                                name="historyLimit"
                                                type="number"
                                                error={touched.historyLimit && Boolean(errors.historyLimit)}
                                                helperText={touched.historyLimit && errors.historyLimit}
                                                variant="outlined"
                                                margin="dense"
                                                className={classes.multiLine}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Field
                                                        name="onlyTicket"
                                                        as={Switch}
                                                        color="primary"
                                                        checked={values.onlyTicket}
                                                    />
                                                }
                                                label={i18n.t("integrationModal.form.onlyTicket")}
                                            />
                                        </div>
                                        {/* <Divider />
                                <div>
                                    <Typography variant="h6" color="textSecondary" gutterBottom>
                                        Ferramentas
                                    </Typography>
                                    <Box className={classes.toolBox}>
                                        <Field
                                            as={TextField}
                                            margin="dense"
                                            disabled
                                            fullWidth
                                            name="tools[0].function.name"
                                            label="Nome da Função"
                                        //value={functionName}
                                        //onChange={(e) => setFunctionName(e.target.value)}
                                        />
                                        <Field
                                            as={TextField}
                                            margin="dense"
                                            fullWidth
                                            name="tools[0].function.description"
                                            label="Descrição"
                                        //value={description}
                                        //onChange={(e) => setDescription(e.target.value)}
                                        />
                                        <Typography variant="h6" color="textSecondary" gutterBottom>
                                            Propriedades
                                        </Typography>
                                        <Box className={classes.toolBox}>
                                            {Object.keys(values.tools[0].function.parameters.properties).map((prop) =>
                                                <>
                                                    <Field
                                                        as={TextField}
                                                        margin="dense"
                                                        label={prop}
                                                        name={`tools[0].function.parameters.properties.${prop}.description`}
                                                    />
                                                </>
                                            )}
                                        </Box>
                                    </Box>
                                    <CreateToolsJson />
                                </div> */}
                                    </>}
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t("integrationModal.buttons.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    {integrationId
                                        ? `${i18n.t("integrationModal.buttons.okEdit")}`
                                        : `${i18n.t("integrationModal.buttons.okAdd")}`}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    )
}
export default IntegrationModal;