//import whatsBackground from "../../src/assets/wa-background-hires.png";
import whatsBackgroundDark from "../../src/assets/wa-background-hires-dark.png";
import { alpha, createTheme } from "@mui/material";
import "../assets/fonts/roboto/styles.css";

const wappDark = createTheme(
    {
        typography: {

            fontFamily: 'Roboto, Arial',
            fontSize: 14,

            h1: {
                fontSize: '1.5rem',
                lineHeight: 1.2,
                fontWeight: 400,
                color: '#37373C',
                margin: '0 0 .5rem'
            },
            h2: {
                fontSize: '1.4rem',
                lineHeight: 1.2,
                fontWeight: 400,
                color: '#37373C',
                margin: '0 0 .5rem'
            },
            h3: {
                fontSize: '1.25rem',
                lineHeight: 1.2,
                fontWeight: 400,
                color: '#37373C',
                margin: '0 0 .5rem'
            },
            h4: {
                fontSize: '1.1rem',
                lineHeight: 1.2,
                fontWeight: 400,
                color: '#37373C',
                margin: '0 0 .5rem'
            },
            h5: {
                fontSize: '1rem',
                lineHeight: 1.2,
                fontWeight: 400,
                color: '#37373C',
                margin: '0 0 .5rem'
            },
            h6: {
                fontSize: '.875rem',
                lineHeight: 1.2,
                fontWeight: 400,
                color: '#37373C',
                margin: '0 0 .5rem'
            },
            body1: {
                fontSize: '.875rem',
            },
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {

                    "::-webkit-scrollbar": {
                        width: "6px",
                        height: "6px"
                    },

                    "::-webkit-scrollbar-track": {
                        background: "#f1f1f100",
                    },

                    "::-webkit-scrollbar-thumb": {
                        background: "#8696a0",
                    },

                    "::-webkit-scrollbar-thumb:hover": {
                        background: "#5d6469",
                    },
                },
            },
            /* MuiIconButton: {
                styleOverrides: {
                    root: {
                        color: 'inherit',
                        background: '#FFFFFF',
                        '&:hover': {
                            background: '#FFFFFF',
                        }
                    }
                },
            }, */
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: "#8696A01F",
                    },
                    root: {
                        '& .MuiSvgIcon-root': {
                            color: "#A7B2B9",
                        },
                    },
                },
            },
            MuiTextField: {
                defaultProps: {
                    size: 'small', // Define o tamanho small como padrão
                },
            },
            MuiFormControl: {
                defaultProps: {
                    size: 'small', // Define o tamanho small como padrão
                },
            },
            MuiPaper: {
                defaultProps: {
                    //variant:"outlined",
                    elevation: 0
                },
                styleOverrides: {
                    // rounded: {
                    //     borderRadius: 12,
                    // },
                    // outlined: {
                    //     borderColor: "#8696A01f", // Defina a cor da borda aqui (exemplo: vermelho)
                    // },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderColor: alpha('#000', 0.1),
                    },
                },
            },
            // MuiMenuItem: {
            //     styleOverrides: {
            //         root: {
            //             minHeight: 'auto',
            //         },
            //     },
            // },
            // MuiCard: {
            //     styleOverrides: {
            //         rounded: {
            //             borderRadius: 12,
            //             boxShadow: `0 0.5rem 1.25rem ${alpha('#7352C7', .175)}`
            //         },
            //     },
            // },

            // MuiCardContent: {
            //     styleOverrides: {
            //         root: {
            //             padding: 24
            //         },
            //     },
            // },
            // MuiCardHeader: {
            //     styleOverrides: {
            //         root: {
            //             padding: '18px 24px'
            //         },
            //         title: {
            //             fontSize: '1.1rem',
            //             marginBottom: 0
            //         },
            //         subheader: {
            //             margin: '4px 0 0'
            //         },
            //         action: {
            //             margin: 0
            //         }
            //     }
            // },
            // MuiCardActions: {
            //     styleOverrides: {
            //         root: {
            //             padding: '8px 24px'
            //         }
            //     }
            // },
            // MuiChip: {
            //     styleOverrides: {
            //         sizeSmall: {
            //             height: 22,
            //             fontSize: 12,
            //         }
            //     }
            // },
            // MuiButton: {
            //     styleOverrides: {
            //         root: {
            //             fontWeight: 400,
            //             letterSpacing: 1
            //         },
            //         sizeSmall: {
            //             fontSize: '12px'
            //         }
            //     }
            // },
            // MuiPopover: {
            //     styleOverrides: {
            //         paper: {
            //             borderRadius: 8
            //         }
            //     }
            // },
            // MuiDialogTitle: {
            //     styleOverrides: {
            //         root: {
            //             fontSize: 18
            //         }
            //     }
            // },
            // MuiDialogActions: {
            //     styleOverrides: {
            //         root: {
            //             padding: '16px 24px'
            //         }
            //     }
            // },
            // MuiAvatarGroup: {
            //     styleOverrides: {
            //         avatar: {
            //             backgroundColor: '#757575',
            //             fontSize: 16
            //         }
            //     }
            // }
        },
        palette: {
            primary: {
                main: "#005949",
                light: "#008069",
                dark: '#005949',
                contrastText: '#FFF'
            },
            secondary: {
                main: "#f44336",
                light: '#ff867e',
                dark: '#c50b28',
                contrastText: '#FFF'
            },
            error: {
                main: '#E73145',
                light: '#FF6A70',
                dark: '#AD001E',
                contrastText: '#FFF'
            },
            warning: {
                main: '#F39711',
                light: '#FFC84C',
                dark: '#BB6900',
                contrastText: '#FFF'
            },
            info: {
                main: '#2EB5C9',
                light: '#04433A',
                dark: '#008598',
                contrastText: '#04433A'
            },
            success: {
                main: '#3BD2A2',
                light: '#78FFD3',
                dark: '#00A073',
                contrastText: '#FFF'
            },
            text: {
                primary: '#ffffff',//Texto
                secondary: '#8696a0',
                disabled: '#A2B2C3',
            },
            shadow: "0 1px .5px #0b141a",
            divider: "#8696A01f",
            background: {
                default: "#111B21",
                paper: "#202C33", 
                header: "#202C33",
                list: "#111B21",
                messagesList: {
                    image: `url(${whatsBackgroundDark})`,
                    dark: "#0b141a",
                }
            },
            action: {
                //active: '#475259',
                //hover: '#111B21',
            },
        },
    }
);
export default wappDark;