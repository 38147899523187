import { Button, FormControl, IconButton, MenuItem, Paper, Select, TextField, Tooltip, Typography } from "@mui/material";
import { i18n } from "../../translate/i18n";
import { Help } from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { AuthContext } from "../../context/Auth/AuthContext";
import useSettings, { changeSettings } from "../../hooks/useSettings";

const useStyles = makeStyles(theme => ({
    mainpaper: {
        padding: theme.spacing(2),
        alignItems: "center",
        marginBottom: 12,
    },
    heading: {
        fontSize: theme.typography.pxToRem(17),
        fontWeight: theme.typography.fontWeightMedium,
    },
    settingOption: {
        marginLeft: "auto",
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        backgroundColor: 'transparent'
    },
    childpaper: {
        backgroundColor: theme.palette.background.default + '5b'
    },
    iconButton: {
        color: theme.palette.text.secondary
    },
}))
const GeneralSettings = () => {

    const classes = useStyles();
    const { user } = useContext(AuthContext);
    const { settings, loading } = useSettings();
    
    const getSettingValue = key => {
        if(loading) return;
        const { value } = settings.find(s => s.key === key);
        return value;
    };
    const [missedCallMsg, setMissedCallMsg] = useState("");
    const [rejectCallMsg, setRejectCallMsg] = useState("");

    useEffect(() => {
        // Atualizar os estados quando os dados estiverem carregados
        if (!loading) {
            setMissedCallMsg(getSettingValue("missedcallmsg") || "");
            setRejectCallMsg(getSettingValue("rejectcallmsg") || "");
        }
    }, [loading, settings]);

    const handleMissedCallMsgChange = (e) => {
        setMissedCallMsg(e.target.value);
    };

    const handleRejectCallMsgChange = (e) => {
        setRejectCallMsg(e.target.value);
    };

    const handleSaveMissedCallMsg = () => {
        changeSettings({ target: { name: "missedcallmsg", value: missedCallMsg } });
    };

    const handleSaveRejectCallMsg = () => {
        changeSettings({ target: { name: "rejectcallmsg", value: rejectCallMsg } });
    };

    return (
        <Paper elevation={0} variant="outlined" className={classes.childpaper}>
            <Paper elevation={0} className={classes.paper}>
                <Typography variant="body1">
                    {i18n.t("settings.userCreation.name")}
                </Typography>

                <FormControl className={classes.settingOption}>
                    <Select
                        margin="dense"
                        id="userCreation-setting"
                        variant="outlined"
                        name="userCreation"
                        value={(settings && settings.length > 0) ? getSettingValue("userCreation") : 'disabled'}
                        onChange={changeSettings}
                        className={classes.settingOption}
                    >
                        <MenuItem value="enabled">
                            {i18n.t("settings.userCreation.options.enabled")}</MenuItem>
                        <MenuItem value="disabled">
                            {i18n.t("settings.userCreation.options.disabled")}</MenuItem>
                    </Select>
                </FormControl>
                <Tooltip arrow interactive title={
                    <React.Fragment>
                        <p>{i18n.t('settings.helpers.userCreation')}</p>
                    </React.Fragment>}
                >
                    <IconButton
                        size="small"
                        className={classes.iconButton}
                    >
                        <Help />
                    </IconButton>
                </Tooltip>
            </Paper>

            <Paper elevation={0} className={classes.paper}>

                <Typography variant="body1">
                    {i18n.t("settings.call.name")}
                </Typography>

                <FormControl className={classes.settingOption}>
                    <Select
                        margin="dense"
                        id="call-setting"
                        variant="outlined"
                        name="call"
                        value={settings && settings.length > 0 ? getSettingValue("call") : 'disabled'}
                        onChange={changeSettings}
                        className={classes.settingOption}
                    >
                        <MenuItem value="enabled">
                            {i18n.t("settings.call.options.enabled")}</MenuItem>
                        <MenuItem value="disabled">
                            {i18n.t("settings.call.options.disabled")}</MenuItem>
                    </Select>
                </FormControl>
                <Tooltip arrow interactive title={
                    <React.Fragment>
                        <p>{i18n.t('settings.helpers.acceptCalls')}</p>
                    </React.Fragment>}
                >
                    <IconButton
                        size="small"
                        className={classes.iconButton}
                    >
                        <Help />
                    </IconButton>
                </Tooltip>
            </Paper>
            <Paper elevation={0} className={classes.paper}>
                <Typography variant="body1">
                    {`${i18n.t("settings.externalmsgsend.title")} `}
                </Typography>

                <FormControl className={classes.settingOption}>
                    <Select
                        margin="dense"
                        id="externalsend-setting"
                        variant="outlined"
                        name="externalsend"
                        value={(settings && settings.length > 0) ? getSettingValue("externalsend") : 'setpending'}
                        onChange={changeSettings}
                        className={classes.settingOption}
                    >
                        <MenuItem value="setpending">
                            {i18n.t("settings.externalmsgsend.options.setpending")}</MenuItem>
                        <MenuItem value="setclosed">
                            {i18n.t("settings.externalmsgsend.options.setclosed")}</MenuItem>
                        <MenuItem value="setlastclosed">
                            {i18n.t("settings.externalmsgsend.options.setlastclosed")}</MenuItem>
                    </Select>
                </FormControl>
                <Tooltip arrow interactive title={
                    <React.Fragment>

                        <p>{i18n.t("settings.helpers.externalSend.title")}</p>
                        <p>{i18n.t("settings.helpers.externalSend.opt1")}</p>
                        <p>{i18n.t("settings.helpers.externalSend.opt2")}</p>
                        <p>{i18n.t("settings.helpers.externalSend.opt3")}</p>

                    </React.Fragment>}
                >
                    <IconButton
                        size="small"
                        className={classes.iconButton}
                    >
                        <Help />
                    </IconButton>
                </Tooltip>
            </Paper>
            <Paper elevation={0} className={classes.paper}>

                <Typography variant="body1">
                    {i18n.t("settings.showdeletedmsg.name")}
                </Typography>

                <FormControl className={classes.settingOption}>
                    <Select
                        margin="dense"
                        id="showdeletedmsg-setting"
                        variant="outlined"
                        name="showdeletedmsg"
                        value={settings && settings.length > 0 ? getSettingValue("showdeletedmsg") : 'disabled'}
                        onChange={changeSettings}
                        className={classes.settingOption}
                    >
                        <MenuItem value="enabled">
                            {i18n.t("settings.showdeletedmsg.options.enabled")}</MenuItem>
                        <MenuItem value="disabled">
                            {i18n.t("settings.showdeletedmsg.options.disabled")}</MenuItem>
                    </Select>
                </FormControl>
                <Tooltip arrow interactive title={
                    <React.Fragment>
                        <p>{i18n.t('settings.helpers.showdeletedmsg')}</p>
                    </React.Fragment>}
                >
                    <IconButton
                        size="small"
                        className={classes.iconButton}
                    >
                        <Help />
                    </IconButton>
                </Tooltip>
            </Paper>
            <Paper elevation={0} className={classes.paper}>
                <TextField
                    id="missedcallmsg-setting"
                    name="missedcallmsg"
                    label="Mensagem de chamada perdida"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    rows={5}
                    onChange={handleMissedCallMsgChange}
                    value={missedCallMsg}
                />
                <Button onClick={handleSaveMissedCallMsg}>OK</Button>
                <Tooltip arrow interactive title={
                    <React.Fragment>
                        <p>{i18n.t('settings.helpers.missedcallmsg')}</p>
                    </React.Fragment>}
                >
                    <IconButton
                        size="small"
                        className={classes.iconButton}
                    >
                        <Help />
                    </IconButton>
                </Tooltip>
            </Paper>

            <Paper elevation={0} className={classes.paper}>
                <TextField
                    id="rejectcallmsg-setting"
                    name="rejectcallmsg"
                    label="Mensagem de chamada desabilitada"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    rows={5}
                    onChange={handleRejectCallMsgChange}
                    value={rejectCallMsg}
                />
                <Button onClick={handleSaveRejectCallMsg}>OK</Button>
                <Tooltip arrow interactive title={
                    <React.Fragment>
                        <p>{i18n.t('settings.helpers.rejectcallmsg')}</p>
                    </React.Fragment>}
                >
                    <IconButton
                        size="small"
                        className={classes.iconButton}
                    >
                        <Help />
                    </IconButton>
                </Tooltip>
            </Paper>

        </Paper>

    )
}
export default GeneralSettings;