import React, { useState, useEffect, useRef, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";
import makeStyles from '@mui/styles/makeStyles';
import { blue, green } from "@mui/material/colors";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Badge from "@mui/material/Badge";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import MarkdownWrapper from "../MarkdownWrapper";
import { Chip, ListItemButton, Tooltip, useMediaQuery } from "@mui/material";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import { Check, Facebook, Instagram, WhatsApp } from "@mui/icons-material";
import HiddenTicket from "./hidden";
import { Can } from "../Can";
import TagsWrapper from "../TagsWrapper";
import TicketListContextMenu from "../TicketListContextMenu";


const useStyles = makeStyles(theme => ({
	ticket: {
		position: "relative",
		height: '65px'
	},

	pendingTicket: {
		cursor: "pointer",
	},

	noTicketsDiv: {
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},

	noTicketsText: {
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},

	noTicketsTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},

	contactNameWrapper: {
		display: "flex",
		justifyContent: "space-between",
	},
	contactNameBreaker: {
		maxWidth: "calc(100% - 100px)",
		textAlign: "center",
		wordWrap: "break-word",
		[theme.breakpoints.down('md')]: {
			maxWidth: "calc(100% - 100px)",
		},
	},

	closedBadge: {
		alignSelf: "center",
		justifySelf: "flex-end",
		whiteSpace:"nowrap",
		marginRight: 32,
		marginLeft: "auto",
	},

	contactLastMessage: {
		paddingRight: 20,
	},

	newMessagesCount: {
		alignSelf: "center",
		marginRight: 8,
		marginLeft: "auto",
	},

	badgeStyle: {
		color: "white",
		backgroundColor: green[500],
		right: -12,
		top: -2,
		padding: '0 5px',
	},
	selectBadge: {
		color: "white",
		backgroundColor: green[500],
		padding: '5px',
	},

	acceptButton: {
		position: "absolute",
		left: "50%",
	},

	ticketQueueColor: {
		flex: "none",
		width: "8px",
		height: "100%",
		position: "absolute",
		top: "0%",
		left: "0%",
		backgroundColor: theme.palette.text.secondary
	},

	userTag: {
		position: "absolute",
		marginRight: 5,
		right: 5,
		bottom: 5,
		color: "#ffffff",
	},

	connectionChip: {
		position: "absolute",
		color: theme.palette.text.primary,
		borderColor: theme.palette.text.primary + "1a",
		marginRight: 5,
		right: 5,
		bottom: 5,
		cursor: "pointer",
	},

	divider: {
		backgroundColor: theme.palette.divider,
	},
	chipContainer: {
		whiteSpace: 'nowrap',
		maxWidth: '80%',
		overflow: 'hidden',
		position: 'relative',
		borderRadius: '50px',
	}
}));

const TicketListItem = ({ settings, ticket, index, onSelectTicket, selectedTickets }) => {
	const classes = useStyles();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const { ticketId } = useParams();
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);


	const handleAcepptTicket = async id => {
		setLoading(true);
		try {
			await api.put(`/tickets/${id}`, {
				status: "open",
				userId: user?.id,
			});
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		if (isMounted.current) {
			setLoading(false);
		}
		history.push(`/tickets/${id}`);
	};

	const getSettingValue = (key) => {
		if (settings && settings.length > 0) {
			const { value } = settings.find(s => s.key === key);
			return value;
		}
	};
	const anonymousTicket = () => {
		const canView = Can({
			role: user.profile,
			perform: "drawer-admin-items:view",
			yes: () => true,
			no: () => false,
		});
		const isAnonymous = getSettingValue('anonymoustickets')
		if (isAnonymous === 'enabled' && !canView) {
			return true;
		} else if (isAnonymous === 'disabled' || canView) {
			return false;
		} else return false;
	}
	const acceptOnlyFirst = () => {
		const canView = Can({
			role: user.profile,
			perform: "drawer-admin-items:view",
			yes: () => true,
			no: () => false,
		});
		const isAcceptOnlyFirst = getSettingValue('acceptonlyfirst')
		if (isAcceptOnlyFirst === 'enabled' && index === 0) {
			return true;
		} else if (isAcceptOnlyFirst == 'disabled' || canView) {
			return true;
		} else {
			return false;
		}

	}

	const connectionChip = (ticket) => {
		let style = { color: "#369b4b", fontSize: 18, marginLeft: 4 }
		const renderIcon = () => {
			switch (ticket.whatsapp?.connectionType) {

				case 'bwhatsapp': case 'whatsapp':
					style = { ...style, color: "#369b4b" }
					return (
						<WhatsApp style={style} />
					)
				case "instagram":
					style = { ...style, color: "#833AB4" }
					return (
						<Instagram style={style} />
					)
				case "facebook":
					style = { ...style, color: "#2576D2" }
					return (
						<Facebook style={style} />
					)
			}
		}

		return (
			<Tooltip
				title={i18n.t("ticketsList.connectionTitle")}
				placement="right"
				arrow
			>
				<Chip
					className={classes.connectionChip}
					variant="outlined"
					size='small'

					icon={renderIcon()}
					label={ticket.whatsapp?.name}
				>
				</Chip>
			</Tooltip>
		)
	}

	const handleSelectTicket = id => {
		if (selectedTickets && selectedTickets.length >= 1) {
			onSelectTicket(id);
		} else {
			if ((!acceptOnlyFirst() || anonymousTicket()) && ticket.status === 'pending') return;
			history.push(`/tickets/${id}`);
		}

	};

	return (
		<div key={ticket.id}>
			<ListItemButton
				dense
				//onContextMenu={handleOpenTicketContextMenu}
				onClick={e => handleSelectTicket(ticket.id)}
				selected={ticketId && +ticketId === ticket.id}
				className={clsx(classes.ticket, {
					[classes.pendingTicket]: ticket.status === "pending",
				})}
			>
				<Tooltip
					arrow
					placement="right"
					title={`${ticket.user?.name || i18n.t("ticketsList.noUser")} | ${ticket.queue?.name || i18n.t("ticketsList.noQueue")}`}
				>
					<span
						style={{ backgroundColor: ticket.queue?.color }}
						className={classes.ticketQueueColor}
					>
					</span>
				</Tooltip>
				{anonymousTicket() && ticket.status === 'pending' ? (
					<HiddenTicket ticket={ticket} />
				) :
					<>
						<ListItemAvatar>
							{selectedTickets && selectedTickets.some((ticketId) => ticket.id === ticketId) ?
								<Badge

									classes={{
										badge: classes.selectBadge,
									}}
									badgeContent={<Check fontSize={"2"} />}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'right',
									}}
								>
									<Avatar src={ticket?.contact?.profilePicUrl} />

								</Badge>
								:
								<Avatar src={ticket?.contact?.profilePicUrl} />
							}
						</ListItemAvatar>
						<ListItemText
							disableTypography
							primary={
								<span className={classes.contactNameWrapper}>
									<Typography
										className={classes.contactNameBreaker}
										noWrap
										component="span"
										variant="body2"
										color="textPrimary"
									>
										{ticket.contact.company ?
											<>
												{ticket.contact.name}
												<Typography
													noWrap
													component="span"
													variant="body2"
													color="textSecondary"
												>
													{` (${ticket.contact.company}) `}
												</Typography>

											</>
											: ticket.contact.name
										}
									</Typography>
									{(ticket.status === "closed" || ticket.status === "expired" || ticket.status === "onreview") && (
										<Badge
											className={classes.closedBadge}
											badgeContent={i18n.t(`ticketsList.badge.${ticket.status}`)}
											color="primary"
										/>
									)}
									<div>
										{ticket.lastMessage && (
											<Typography
												component="span"
												variant="body2"
												color="textSecondary"
											>
												{isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
													<>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
												) : (
													<>{format(parseISO(ticket.updatedAt), "dd/MM/yy")}</>
												)}
											</Typography>
										)}
										<Badge
											className={classes.newMessagesCount}
											badgeContent={ticket.unreadMessages}
											classes={{
												badge: classes.badgeStyle,
											}}
										/>
									</div>


									{ticket?.whatsappId && connectionChip(ticket)}
								</span>
							}

							secondary={<>
								<span className={classes.contactNameWrapper}>
									<Typography
										className={classes.contactLastMessage}
										noWrap
										style={{ maxWidth: 250, align: "center" }}
										component="span"
										variant="body2"
										color="textSecondary"
									>
										{ticket.lastMessage ? (
											<MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>

										) : (
											<br />
										)}
									</Typography>
								</span>
								<div className={classes.chipContainer}>

									<TagsWrapper tags={ticket.contact.tags} maxWidth={230} />
								</div>

							</>
							}
						/></>}

				{ticket.status === "pending" && acceptOnlyFirst() && (
					<>

						<ButtonWithSpinner
							color="primary"
							variant="contained"
							className={classes.acceptButton}
							size="small"
							loading={loading}
							disabled={selectedTickets?.length >= 1}
							onClick={e => handleAcepptTicket(ticket.id)}
						>
							{i18n.t("ticketsList.buttons.accept")}
						</ButtonWithSpinner>
					</>
				)}
			</ListItemButton>

			<Divider className={classes.divider} variant="inset" component="li" />
		</div>
	);
};

export default TicketListItem;
