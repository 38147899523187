import { useState, useEffect, useReducer } from "react";
import openSocket from "../../services/socket-io";
import toastError from "../../errors/toastError";

import api from "../../services/api";

const reducer = (state, action) => {
    if (action.type === "LOAD_INTEGRATIONS") {
        const integrations = action.payload;
        const newIntegration = [];

        integrations.forEach((integration) => {
            const integrationIndex = state.findIndex((i) => i.id === integration.id);
            if (integrationIndex !== -1) {
                state[integrationIndex] = integration;
            } else {
                newIntegration.push(integration);
            }
        });

        return [...state, ...newIntegration];
    }

    if (action.type === "UPDATE_INTEGRATIONS") {
        const integration = action.payload;
        const integrationIndex = state.findIndex((i) => i.id === integration.id);

        if (integrationIndex !== -1) {
            state[integrationIndex] = integration;
            return [...state];
        } else {
            return [...state];
        }
    }

    if (action.type === "DELETE_INTEGRATION") {
        const integrationId = action.payload;

        const integrationIndex = state.findIndex((i) => i.id === integrationId);
        if (integrationIndex !== -1) {
            state.splice(integrationIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
}

const useIntegrations = ({ searchParam, pageNumber, showAll = false }) => {
    const [integrations, dispatch] = useReducer(reducer, []);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);
    const [count,setCount] = useState(0);

    useEffect(() => {
        dispatch({ type: "RESET" });
    }, [searchParam]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get("/integrations/", {
                        params: { searchParam, pageNumber },
                    });
                    dispatch({ type: "LOAD_INTEGRATIONS", payload: data.integrations });
                    setHasMore(data.hasMore);
                    setCount(data.count)
                } catch (err) {
                    toastError(err);
                }
                setLoading(false);
            };
            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber]);

    useEffect(() => {
        const socket = openSocket();
        socket.on("integration", (data) => {
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_INTEGRATIONS", payload: data.integration });
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_INTEGRATION", payload: +data.integrationId });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    return { integrations, hasMore, loading, count };
};

export default useIntegrations;