import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { createTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import { ButtonGroup, IconButton } from "@mui/material";
import { Chat, MoreVert, Replay } from "@mui/icons-material";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import CloseTicketModal from "../CloseTicketModal";

const useStyles = makeStyles(theme => ({
	actionButtons: {
		display: "flex",
		marginRight: 6,
		flex: "none",
		padding: 0,
		alignItems: "center",
		marginLeft: "auto",
		"& > *": {
			margin: theme.spacing(1),
		},
	},
	iconButton: {
		color: theme.palette.text.secondary,
	}
}));

const TicketActionButtons = ({ ticket, setShowHistory, showHistory }) => {
	const classes = useStyles();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(false);
	const [closeTickekModalOpen, setCloseTicketModalOpen] = useState(false);
	const ticketOptionsMenuOpen = Boolean(anchorEl);
	const { user } = useContext(AuthContext);
	const { settings, loading: loadingSettings } = useContext(SettingsContext);

	const handleOpenTicketOptionsMenu = e => {
		setAnchorEl(e.currentTarget);
	};

	const handleCloseTicketOptionsMenu = e => {
		setAnchorEl(null);
	};

	const getSettingValue = (key) => {
		if (settings && settings.length > 0) {
			const { value } = settings.find(s => s.key === key);
			return value;
		}
	};
	const isGroupAsTicket = getSettingValue('groupsasticket') === "enabled" ? true : false;


	const anonymousTicket = () => {
		const canView = Can({
			role: user.profile,
			perform: "drawer-admin-items:view",
			yes: () => true,
			no: () => false
		});
		const isAnonymous = getSettingValue('anonymoustickets');
		if (isAnonymous === 'enabled' && !canView) {
			return true;
		} else if (isAnonymous === 'disabled' || canView) {
			return false;
		} else return false;
	}

	const handleUpdateTicketStatus = async (e, status, userId) => {
		setLoading(true);
		if (status === "closed" && ticket.status !== "onreview") {
			setCloseTicketModalOpen(true);
		} else {
			try {
				await api.put(`/tickets/${ticket.id}`, {
					status: status,
					userId: userId || null,
				});

				setLoading(false);
				if (status === "open") {
					history.push(`/tickets/${ticket.id}`);
				} else {
					history.push("/tickets");
				}
			} catch (err) {
				setLoading(false);
				toastError(err);
			}
		}
	};

	const handleNewTicket = async (e, contactId) => {
		if (!contactId) return;
		setLoading(true);
		try {
			const { data: ticket } = await api.post("/tickets", {
				contactId: contactId,
				userId: user.id,
				status: "open",
			});
			history.push(`/tickets/${ticket.id}`);
		} catch (err) {
			toastError(err);
		}
		setLoading(false);
	};

	const handleCloseModal = () => {
		setCloseTicketModalOpen(false);
		setLoading(false);
	}

	const notBelongsToUserQueues = () => ticket.queueId && user.queues.map(queue => queue.id).indexOf(ticket.queueId) === -1 && user.profile !== "admin";

	return (
		<div className={classes.actionButtons}>
			{!notBelongsToUserQueues() && <>
				{ticket.status === "closed" && (
					<ButtonGroup>
						{ticket.isGroup && isGroupAsTicket || !ticket.isGroup &&
							<ButtonWithSpinner
								loading={loading}
								size="small"
								onClick={e => handleNewTicket(e, ticket.contactId)}
							>
								{i18n.t("messagesList.header.buttons.new")}
							</ButtonWithSpinner>
						}
						{(ticket.userId === user.id || user.profile === 'admin' || ticket.isGroup) &&
							<ButtonWithSpinner
								loading={loading}
								size="small"
								onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
							>
								{i18n.t("messagesList.header.buttons.reopen")}
							</ButtonWithSpinner>
						}
					</ButtonGroup>
				)}
				{ticket.status === "open" && (ticket.userId === user.id || user.profile === 'admin') && (!ticket.isGroup || (ticket.isGroup && isGroupAsTicket) )&& (
					<ButtonGroup>
						{!anonymousTicket() &&
							<ButtonWithSpinner
								loading={loading}
								size="small"
								onClick={e => handleUpdateTicketStatus(e, "pending", null)}
							>
								{i18n.t("messagesList.header.buttons.return")}
							</ButtonWithSpinner>
						}
						<ButtonWithSpinner
							loading={loading}
							disableElevation
							size="small"
							variant="contained"
							color="primary"
							onClick={e => handleUpdateTicketStatus(e, "closed", user?.id)}
						>
							{i18n.t("messagesList.header.buttons.resolve")}
						</ButtonWithSpinner>

					</ButtonGroup>
				)}
				{ticket.status === "pending" && (
					<ButtonWithSpinner
						loading={loading}
						size="small"
						disableElevation
						variant="contained"
						color="primary"
						onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
					>
						{i18n.t("messagesList.header.buttons.accept")}
					</ButtonWithSpinner>
				)}
			</>}

			<IconButton
				className={classes.iconButton}
				onClick={handleOpenTicketOptionsMenu}
				size="large">
				<MoreVert />
			</IconButton>
			<TicketOptionsMenu
				ticket={ticket}
				anchorEl={anchorEl}
				showHistory={showHistory}
				setShowHistory={setShowHistory}
				menuOpen={ticketOptionsMenuOpen}
				handleClose={handleCloseTicketOptionsMenu}
			/>
			<CloseTicketModal
				modalOpen={closeTickekModalOpen}
				onClose={handleCloseModal}
				ticketId={ticket.id}
				ticketData={ticket}
				userId={user?.id}
			/>
		</div>
	);
};

export default TicketActionButtons;
