import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field, FieldArray } from "formik";
import { toast } from "react-toastify";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	Select,
	InputLabel,
	MenuItem,
	FormControl,
	TextField,
	InputAdornment,
	IconButton,
	Typography,
	Divider
} from '@mui/material';

import { Visibility, VisibilityOff } from '@mui/icons-material';

import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import useWhatsApps from "../../hooks/useWhatsApps";
import useIntegrations from "../../hooks/useIntegrations";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: theme.palette.primary.main,
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	iconButton: {
		color: theme.palette.text.secondary
	}
}));

const UserSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Muito curto!")
		.max(50, "Muito longo")
		.required("Obrigatório!"),
	password: Yup.string().min(5, "Muito curto").max(50, "Muito longo"),
	email: Yup.string().email("Email inválido").required("Obrigatório!"),
});

const UserModal = ({ open, onClose, userId }) => {
	const classes = useStyles();

	const initialState = {
		name: "",
		email: "",
		password: "",
		profile: "user",
		whatsappId: "",
		queueIds: [],
		movideskAgentData: []
	};

	const { user: loggedInUser } = useContext(AuthContext);
	const { integrations } = useIntegrations({ showAll: true });
	const integratedMovidesks = integrations.filter((integration) => integration.type === "movidesk");
	const [user, setUser] = useState(initialState);
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	const [showPassword, setShowPassword] = useState(false);
	const { loading, whatsApps } = useWhatsApps();

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {

			const fetchUser = async () => {
				if (!userId) return;
				try {
					const { data } = await api.get(`/users/${userId}`);
					setUser(prevState => {
						return { ...prevState, ...data };
					});
					const userQueueIds = data.queues?.map(queue => queue.id);
					setSelectedQueueIds(userQueueIds);
				} catch (err) {
					toastError(err);
				}
			};

			fetchUser();
		}, 500)
		return () => clearTimeout(delayDebounceFn)
	}, [userId, open]);

	const handleClose = () => {
		onClose();
		setUser(initialState);
	};

	const handleSaveUser = async values => {
		const userData = { ...values, queueIds: selectedQueueIds };
		try {
			if (userId) {
				await api.put(`/users/${userId}`, userData);
			} else {
				await api.post("/users", userData);
			}
			toast.success(i18n.t("userModal.success"));
		} catch (err) {
			toastError(err);
		}
		handleClose();
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle
					color="textPrimary"
					id="form-dialog-title"
				>
					{userId
						? `${i18n.t("userModal.title.edit")}`
						: `${i18n.t("userModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={user}
					enableReinitialize={true}
					validationSchema={UserSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveUser(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form>
							<DialogContent dividers>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("userModal.form.name")}
										autoFocus
										name="name"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
									<Field
										as={TextField}
										name="password"
										variant="outlined"
										margin="dense"
										label={i18n.t("userModal.form.password")}
										error={touched.password && Boolean(errors.password)}
										helperText={touched.password && errors.password}
										type={showPassword ? 'text' : 'password'}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														className={classes.iconButton}
														aria-label="toggle password visibility"
														onClick={() => setShowPassword((e) => !e)}
														size="large">
														{showPassword ? <VisibilityOff /> : <Visibility />}
													</IconButton>
												</InputAdornment>
											)
										}}
										fullWidth
									/>
								</div>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("userModal.form.email")}
										name="email"
										error={touched.email && Boolean(errors.email)}
										helperText={touched.email && errors.email}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
									<Can
										role={loggedInUser.profile}
										perform="user-modal:editProfile"
										yes={() => (
											<FormControl
												variant="outlined"
												className={classes.formControl}
												margin="dense"
											>
												<InputLabel id="profile-selection-input-label">
													{i18n.t("userModal.form.profile")}
												</InputLabel>
												<Field
													as={Select}
													label={i18n.t("userModal.form.profile")}
													name="profile"
													labelId="profile-selection-label"
													id="profile-selection"
													required
												>
													<MenuItem value="admin">Admin</MenuItem>
													<MenuItem value="user">User</MenuItem>
												</Field>
											</FormControl>
										)}
									/>
								</div>
								<Can
									role={loggedInUser.profile}
									perform="user-modal:editQueues"
									yes={() => (
										<QueueSelect
											selectedQueueIds={selectedQueueIds}
											onChange={values => setSelectedQueueIds(values)}
										/>
									)}
								/>
								<Can
									role={loggedInUser.profile}
									perform="user-modal:editQueues"
									yes={() => (!loading &&
										<>
											<FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
												<InputLabel>{i18n.t("userModal.form.whatsapp")}</InputLabel>
												<Field
													as={Select}
													label={i18n.t("userModal.form.whatsapp")}
													name="whatsappId"
												>
													{whatsApps.map((whatsapp) => (
														<MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
													))}
												</Field>
											</FormControl>

											{integratedMovidesks.length > 0 &&
												<FieldArray name="movideskAgentData">
													{() => (
														<>
															{integratedMovidesks.map((movidesk, index) => {
																values.movideskAgentData.sort((a, b) => b.id - a.id);
																if (!values.movideskAgentData[index]?.movideskId) {
																	values.movideskAgentData[index] = { ...values.movideskAgentData[index], movideskId: movidesk.id };
																}
																//values.movideskAgentData.sort((a, b) => a.id - b.id);
																return (
																	<div key={movidesk.id}>
																		<Divider />
																		<Typography color="textSecondary">
																			{i18n.t("userModal.form.title", { movideskName: movidesk.name })}
																		</Typography>
																		<Field
																			as={TextField}
																			label={i18n.t("userModal.form.agentId")}
																			name={`movideskAgentData[${index}].agentId`}
																			// error={form.errors.movideskAgentData?.[movideskAgentDataIndex]?.agentId}
																			// helperText={form.errors.movideskAgentData?.[movideskAgentDataIndex]?.agentId}
																			variant="outlined"
																			margin="dense"
																			fullWidth
																		/>
																		<Field
																			as={TextField}
																			label={i18n.t("userModal.form.agentTeam")}
																			name={`movideskAgentData[${index}].agentTeam`}
																			// error={form.errors.movideskAgentData?.[movideskAgentDataIndex]?.agentTeam}
																			// helperText={form.errors.movideskAgentData?.[movideskAgentDataIndex]?.agentTeam}
																			variant="outlined"
																			margin="dense"
																			fullWidth
																		/>
																	</div>
																);
															})}
														</>
													)}
												</FieldArray>
											}

										</>)}
								/>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("userModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{userId
										? `${i18n.t("userModal.buttons.okEdit")}`
										: `${i18n.t("userModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default UserModal;
